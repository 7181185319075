/* Container for the FAQ section */

.faq-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

/* Container for the search bar */
.faq-search {
  margin-bottom: 20px;
  text-align: center;
}

/* Styling for the search input */
.faq-search input {
  width: 60%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* FAQ section with accordion cards */
.faqsec {
  margin-top: 20px;
}

/* Style for each card in the accordion */
.faqsec .fpanel-group .fcard {
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

/* Style for the panel heading (question) */
.faqsec .fpanel-heading {
  padding: 10px;
  background: #f7f7f7;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Style when panel heading is collapsed */
.faqsec .fpanel-heading.collapsed {
  background: #fff;
}

/* Link style inside the panel heading */
.faqsec .fpanel-heading a {
  text-decoration: none;
  color: #333;
  display: block;
  width: 100%;
}

/* Icon for expanding/collapsing accordion */
.faqsec .fpanel-heading::after {
  content: "\25BC"; /* Down arrow */
  font-size: 12px;
  color: #333;
  transform: rotate(0deg);
  transition: transform 0.3s;
}

/* Rotate the icon when expanded */
.faqsec .fpanel-heading:not(.collapsed)::after {
  transform: rotate(180deg); /* Up arrow */
}

/* Style for the panel body (answer) */
.faqsec .collapse {
  border-top: none;
}

.faqsec .card-body {
  padding: 10px;
  background: #fff;
}

/* Responsive styling for search input */
@media (max-width: 768px) {
  .faq-search input {
    width: 90%;
  }
}
