.sidebar-section {
  /* background-color: #ffffff; */
  height: 100vh;
  box-shadow: 0 0 10px 0 var(--darker-gray);
  position: sticky;
  top: 0%;
  transition: width 0.3s ease, padding 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.sidebar-section.open {
  width: 18vw;
}

.sidebar-section.closed {
  width: 4vw;
  padding: 1rem 0.5rem;
}

.imageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.imageDiv img {
  transition: opacity 0.3s ease, transform 0.3s ease;
  height: 140px;
  width: 140px;
  object-fit: contain;
}

.sidebar-section.closed .imageDiv img {
  height: 40px;
  width: 40px;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  align-items: center;
  transition: opacity 0.3s ease;
}

.sidebar-container > ul,
.imageDiv,
.logout-container {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.sidebar-section.closed .sidebar-container > ul,
.sidebar-section.closed .imageDiv,
.sidebar-section.closed .logout-container {
  opacity: 0;
  pointer-events: none; /* Ensures hidden elements don't capture any pointer events */
}

.sidebar-container > ul {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  align-items: center;
}

.sidebar-container ul > li,
.logout-container {
  font-size: 1.2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

a {
  color: black;
}

.logout-container {
  display: flex;
  cursor: pointer;
}

.toggle-btn {
  font-size: 1.5rem;
  cursor: pointer;
  align-self: flex-end;
  margin: 1rem 0;
}

.sidebar-container a,
.logout-container div {
  cursor: pointer;
}

.sidebar-container a:hover,
.logout-container div:hover {
  cursor: pointer;
}

/* Adjustments for different screen sizes */
@media (min-width: 1127px) {
  .sidebar-section.open {
    width: 18vw;
  }

  .sidebar-section.closed {
    width: 4vw;
  }
}

@media (max-width: 1126px) {
  .sidebar-section.open {
    width: 22vw;
  }

  .sidebar-section.closed {
    width: 4vw;
  }
}

@media (max-width: 875px) {
  .sidebar-section.open {
    width: 25vw;
  }

  .sidebar-section.closed {
    width: 4vw;
  }
}

@media (max-width: 700px) {
  .sidebar-section.open {
    width: 30vw;
  }

  .sidebar-section.closed {
    width: 5vw;
  }
}

@media (max-width: 537px) {
  .sidebar-section.open {
    width: 40vw;
  }

  .sidebar-section.closed {
    width: 6vw;
  }
}

@media (max-width: 426px) {
  .sidebar-section.open {
    width: 45vw;
  }

  .sidebar-section.closed {
    width: 7vw;
  }
}

@media (max-width: 375px) {
  .sidebar-section.open {
    width: 50vw;
  }

  .sidebar-section.closed {
    width: 8vw;
  }

  .sidebar-container ul > li,
  .logout-container {
    font-size: 1rem;
  }
}

@media (max-width: 320px) {
  .sidebar-section.open {
    width: 60vw;
  }

  .sidebar-section.closed {
    width: 9vw;
  }

  .sidebar-container ul > li,
  .logout-container {
    font-size: 0.9rem;
  }
}
