/* General reset styles */
/* body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* Header styling */
header {

  width: 100%;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  /* background-color: aqua; */
  z-index: 2;
  overflow: hidden;
}

/* Navbar styling */
nav {
  width: 100%;
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* background-color: blue; */
  /* height: 60px; */
  /* align-self: center; */
  /* align-items: center; */
}

.logoDiv{
  /* margin: auto; */
  height:"100%";
  /* display:"flex"; */
  /* background-color:forestgreen; */
  /* align-items: center; */
  /* justify-content: center; */
  /* align-self: center; */
  /* margin-left: 10px; */
}

.insideNavDiv{
  /* height:"100%"; */
  /* display:"flex"; */
  /* background-color:forestgreen; */
}

.nav-logo a,
.nav-links a {
  color: var(--bold-text-color);
  text-decoration: none;
  
}

.nav-logo {
  margin: auto;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  align-self: center;
  gap: 5px;
  /* margin: auto; */
}

.nav-logo img {
  height: 50px;
  width: 50px;
  /* margin-top: 5px; */
  /* display: flex; */
  /* align-self: center; */
  /* background-color: forestgreen; */
}

.nav-links {
  display: flex;
  gap: 2rem;
  /* background-color: aqua; */
  align-items: center;
  margin: auto;
}

.nav-links .btn {
  color: var(--white);
  font-size: 0.8rem;
  padding: 0.4rem 0.8rem;
  background-color: var(
    --bold-text-color
  ); /* Assuming this variable is defined */
  border-radius: 5px;
  text-decoration: none;
}

.menu-icons {
  display: none;
  z-index: 100;
  font-size: 1.5rem;
  position: relative;
}

.menu-open,
.menu-close {
  color: var(--black);
  cursor: pointer;
}

/* @media (max-width: 1300px) {
  nav {
    flex-direction: column;
    gap: 0.5rem;
  }
} */

@media (max-width: 950px) {
  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--light-blue); /* Assuming this variable is defined */
    flex-direction: column;
    transform: translateX(100%);
    justify-content: center;
    align-items: center;
    visibility: hidden;
    z-index: 20;
    gap: 3rem;
    transition: all 0.3s linear;
  }
  .nav-active .nav-links {
    transform: translateX(0%);
    visibility: visible;
  }
  .menu-icons {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
