/* CustomModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 1000px;
  width: 100%;
  max-height: 80vh; /* Limit the height */
  overflow-y: auto; /* Make content scrollable */
  position: relative;
}

.rte-container {
  max-height: 300px; /* Adjust based on your needs */
  overflow-y: auto;
  margin-top: 10px; /* Add some margin for better spacing */
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
