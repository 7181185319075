
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 80%;
    margin: 2rem auto;
  }

.page-heading {
    font-size: 2rem;
    text-align: center;
    margin: 0 auto;
  }
.hero-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex: 1;
    text-align: justify;
text-justify: inter-word;
  }
  

.about {
    display: flex;
    margin: 0 auto;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
  }
.hero-mg {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%; /* Ensure the container doesn't overflow */
  }
.hero-mg img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    max-height: 80%; /* Ensure the image does not overflow */
    display: block; /* Ensures no extra space under the image */
  }


  
@media screen and (max-width: 770px) {
    .hero {
      height: auto;
    }
    .hero,
    .about {
      flex-direction: column;
    }
    .hero-content {
      margin-top: 2rem;
      align-items: center;
    }
    .hero-content h1 {
      text-align: center;
    }
    .about-heading {
      margin-top: 4rem;
    }
  }
  