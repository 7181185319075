/* Blogs.css */

/* body{
  background-color: white;
} */

.blogs-container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden; /* Ensure no overflow issues */
}

.blog-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.blog-card span {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.thumbnail {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
}

.btn-group {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.btn {
  background-color: #1e375a;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px; /* Adjust spacing between buttons */
}

.form-btn {
  margin-top: 10px;
}

@media screen and (max-width: 490px) {
  .blogs-list {
    /* display: flex; */
    /* align-self: center; */
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    gap: 20px;
  }
}

@media screen and (min-width: 500px) {
  .blogs-list {
    /* display: flex; */
    gap: 20px;
  }
}

@media screen and (min-width: 500px) {
  .blogs-list {
    display: flex;
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    gap: 20px;
  }
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .blogs-list {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 15px;
  }

  .blog-card {
    padding: 10px;
  }

  .blog-card span {
    font-size: 1rem;
  }

  .thumbnail {
    margin-bottom: 8px;
  }

  .btn {
    padding: 6px 10px;
    font-size: 14px;
  }
}

.blog-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.blog-form input,
.blog-form select,
.blog-form .ql-container {
  padding: 10px;
  font-size: 16px;
}

.blog-form .ql-container {
  height: 200px;
}

.blog-form button {
  padding: 10px;
  font-size: 16px;
  background: #1e375a;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.blog-form button:hover {
  background: #0056b3;
}
