@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.birthdaybody {
  background: #008888;
  display: grid;
  place-items: center;
  height: 100vh;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.birthdaycard {
  background: #fdfdfd;
  border-radius: 4px;
  height: 85vh;
  width: 80vw;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media only screen and (min-width: 1000px) {
  .birthdaycard {
    flex-direction: row-reverse;
  }
  .birthdaycard img.birthday {
    width: 100%;
    /* max-width: 30vw; */
    /* max-height: unset; */
  }
}

@media only screen and (max-height: 1180px) {
  /* .birthdaycard {
    flex-direction: row-reverse;
  } */
  img.birthday {
    width: 100%;
    object-fit: contain;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .birthdaycard {
    /* max-height: unset; */
  }
}

@media only screen and (max-width: 640px) {
  /* .birthdaycard {
    flex-direction: row-reverse;
  } */
  img.birthday {
    width: 100%;
    object-fit: contain;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .birthdaycard {
    
    
    /* max-height: unset; */
  }
}

img.birthday {
  height: 100%;
  object-fit: contain;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.birthdaytext {
  padding: 1em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.birthdaymuted {
  opacity: 0.8;
}

.birthdayspace {
  height: 100px;
}
