.blog-content-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.blog-thumbnail {
  width: 100%;
  height: auto;
  margin: 20px 0;
}

.blog-content {
  margin-top: 20px;
}
