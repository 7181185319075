@import url(app.css);

.register-section {
  width: 100%;
  height: 100vh;
  background-color: white;
}

.register-container {
  flex-direction: column;
  gap: 2rem;
  width: 30%;
}

.form-heading {
  font-size: 2rem;
}

.register-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-input,
textarea,
select {
  font-size: 1rem;
  background-color: rgb(240, 239, 239);
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-family: var(--font-family);
}

.form-input:focus,
textarea:focus,
select:focus {
  outline: none;
  background-color: rgb(240, 239, 239);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login-link {
  color: var(--darker-blue);
}

@media (max-width: 1184px) {
  .register-container {
    width: 30%;
  }
}

@media (max-width: 1120px) {
  .register-container {
    width: 35%;
  }
}

@media (max-width: 746px) {
  .register-container {
    width: 40%;
  }
}

@media (max-width: 600px) {
  .register-container {
    width: 60%;
  }
}

@media (max-width: 408px) {
  .register-container {
    width: 70%;
  }
}
