@import url(app.css);

footer {
  background-color: var(--light-text-color);
}

.footer {
  display: flex;
  width: 50%;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  margin: auto;
  padding: 2rem 0;
}

.footer h3 {
  color: white;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.footer a {
  color: var(--light-blue);
}

.footer-links ul {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.social h3 {
  color: white;
}

.social ul {
  display: flex;
  gap: 1rem;
}

.social li {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social svg {
  font-size: 1.3rem;
}

.facebook {
  background-color: white;
}

.instagram {
  background: linear-gradient(
    180deg,
    rgba(64, 93, 230, 1) 0%,
    rgba(131, 58, 180, 1) 25%,
    rgba(225, 48, 108, 1) 50%,
    rgba(245, 96, 64, 1) 75%,
    rgba(252, 175, 69, 1) 100%
  );
}

.youtube {
  background-color: white;
}

.footer-bottom {
  text-align: center;
  background-color: var(--bold-text-color);
  color: var(--light-blue);
  padding: 0.3rem 0;
}

.footer-bottom a {
  color: var(--dark-blue);
}

@media screen and (max-width: 550px) {
  .social ul {
    /* flex-direction: column; */
    align-items: center;
  }
}

.footer_section {
  background: #1e3763;
  color: #ffffff;
  padding: 30px 0 15px 0;
  position: relative;
}

.footer_section h4 {
  font-weight: 600;
  margin-bottom: 20px;
  color: white;
}

.footer_section .footer_col {
  margin-bottom: 30px;
}

.footer_section .footer_contact .contact_link_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer_section .footer_contact .contact_link_box a {
  margin: 5px 0;
  color: #ffffff;
}

.footer_section .footer_contact .contact_link_box a i {
  margin-right: 5px;
}

.footer_section .footer_contact .contact_link_box p {
  color: white;
}

.footer_section .footer_contact .contact_link_box a:hover {
  color: #62d2a2;
}
.footer_section .footer_contact p {
  color: white;
}

.footer_section .footer_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

.footer_section .footer_social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  border-radius: 100%;
  margin-right: 10px;
  font-size: 24px;
}

.footer_section .footer_social a:hover {
  color: #62d2a2;
}

.footer_section .footer_links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer_section .footer_links a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 15px;
  color: #ffffff;
}

.footer_section .footer_links a:hover {
  color: #62d2a2;
}

.footer_section form input {
  border: none;
  background-color: #fefefe;
  width: 100%;
  height: 45px;
  color: #000000;
  outline: none;
  border-radius: 5px;
  padding: 0 15px;
}

.footer_section form input::-webkit-input-placeholder {
  color: #777;
}

.footer_section form input:-ms-input-placeholder {
  color: #777;
}

.footer_section form input::-ms-input-placeholder {
  color: #777;
}

.footer_section form input::placeholder {
  color: #777;
}

.footer_section form button {
  width: 100%;
  text-align: center;
  display: inline-block;
  padding: 10px 55px;
  background-color: #62d2a2;
  color: #ffffff;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #62d2a2;
  margin-top: 15px;
}

.footer_section form button:hover {
  background-color: transparent;
  color: #62d2a2;
}

.footer_section .footer-info {
  text-align: center;
}

.footer_section .footer-info p {
  color: #ffffff;
  padding: 25px 0;
  margin: 0;
}

.footer_section .footer-info p a {
  color: inherit;
}

.footer_detail p {
  color: white;
}
