.sub-sections-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden;
  /* background-color: #f9f9f9; */
  /* border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  word-wrap: break-word;
}

h3 {
  word-wrap: break-word;
}

.main-section-dropdown {
  margin-bottom: 20px;
  text-align: center;
}

.main-section-dropdown select {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 50%;
}

.sub-sections-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.sub-section-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
}

.sub-section-card h3 {
  margin-top: 0;
  color: #333;
}

.sub-section-card p {
  color: #666;
}

.sub-section-card .thumbnail {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.sub-section-card .btn {
  margin-top: 10px;
  margin-right: 5px;
}

.sub-section-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.sub-section-form input {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.sub-section-form button {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  background-color: #1e375a;
  color: white;
  cursor: pointer;
}

.sub-section-form button:hover {
  background-color: #0056b3;
}

.modal-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content .edit-thumbnail {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.modal-content .btn {
  margin-top: 10px;
}

.modal-content .btn-close {
  background-color: #dc3545;
}

.modal-content .btn-close:hover {
  background-color: #c82333;
}
